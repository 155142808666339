import React from 'react';
import PropTypes from 'prop-types';
import Table from '../general/Table';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {RIGHTS} from '../../constants/Rights';
import {formatProductName, formatUnitPrice} from '../../lib/formatting';
import {ProductCrossReference} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import Link from '../navigation/Link';
import ProductCrossDeviations from './ProductCrossDeviations';
import TradeActions from './table/TradeActions';
import ProductMoves from '../project/ProductMoves';
import ProductAvailability from '../project/productTable/ProductAvailability';
import SpecialPrice from '../project/productTable/SpecialPrice';
import PriceList from '../project/productTable/PriceList';
import { isNumber, numberOrNull } from '../../lib/number';
import PurchasePricesModal from '../project/productTable/PurchasePricesModal';

/**
 * @dusan
 */

class ProductCrossRefTable extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number,
        crossReferences: PropTypes.arrayOf(ProductCrossReference.isRequired).isRequired,
        changeOrder: PropTypes.func.isRequired,
        orderBy: PropTypes.string,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const { customerId, crossReferences, orderBy, changeOrder, reload } = this.props;
        return <Table
            class="product-cross-refs-table"
            BodyRow={ProductCrossRefTableRow}
            data={crossReferences}
            orderBy={orderBy}
            changeOrder={changeOrder}
            customerId={customerId}
            reload={reload}
            colDefs={[
                {
                    class: "product-cross-refs-designation",
                    headerCell: <Trans>Označenie</Trans>,
                    orderCol: 'designation'
                },
                {
                    class: "product-cross-refs-manufacturer",
                    headerCell: <Trans>Výrobca</Trans>,
                    orderCol: 'manufacturer'
                },
                {
                    class: "product-cross-refs-original",
                    headerCell: <Trans>Náhrada za</Trans>
                },
                {
                    class: "product-cross-refs-availability",
                    headerCell: <Trans>Dostupnosť</Trans>,
                    orderCol: 'quantity_available'
                },
                {
                    class: "product-cross-refs-unit-price",
                    headerCell: <Trans>Jedn. cena bez DPH</Trans>,
                    orderCol: 'unit_price'
                },
                {
                    class: "product-cross-refs-actions"
                },
            ]}
        />;
    }

}

export default ProductCrossRefTable;

class ProductCrossRefTableRow extends React.PureComponent {
    static propTypes = {
        data: ProductCrossReference.isRequired,
        customerId: PropTypes.number,
        reload: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            amount: null,
        };
    }
    
    changeAmount = (newAmount) => {
        this.setState({amount: newAmount});
    };

    render() {
        const {data, customerId, reload} = this.props;
        const {amount} = this.state;
        const amountChecked = numberOrNull(amount);
        const quantityMin = data.quantity_min != null ? Number(data.quantity_min) : 1.0;

        return <tr>
            <td className="overflow-wrap-break-word">
                <Link
                    className="px-2 text-dark"
                    to={ROUTES.PRODUCT_DETAILS}
                    queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id}}
                    title={<Trans>Detail produktu</Trans>}
                >
                    {data.designation}
                </Link>
                <ProductMoves
                    productDesignation={data.designation}
                    productManufacturer={data.manufacturer}
                    customerId={customerId}
                    productId={data.id}
                />
            </td>
            <td>{data.manufacturer}</td>
            <td>
                { data.replaces != null && Array.isArray(data.replaces) && data.replaces.length > 0 ?
                    <ul>
                        {data.replaces.map((orig, idx) => {
                            const origLabel = formatProductName(orig.designation, orig.manufacturer);
                            return <li key={idx}>
                                { orig.id_product != null ?
                                    <Link
                                        className="px-2 text-dark"
                                        to={ROUTES.PRODUCT_DETAILS}
                                        queryParams={{[QUERY_PARAMS.ID_PRODUCT]: orig.id_product}}
                                        title={<Trans>Detail produktu</Trans>}
                                    >
                                        {origLabel}
                                    </Link> : origLabel
                                }
                                { orig.deviations != null && orig.deviations.length > 0 ?
                                    <ProductCrossDeviations
                                        className="pl-2 list-inline-item"
                                        originalDesignation={orig.designation}
                                        originalManufacturer={orig.manufacturer}
                                        replacementDesignation={data.designation}
                                        replacementManufacturer={data.manufacturer}
                                        deviations={orig.deviations}
                                    /> : null
                                }
                            </li>;
                        })}
                    </ul> : '-'
                }
            </td>
            <td className="text-right">
                <ProductAvailability
                    productId={data.id}
                    productDesignation={data.designation}
                    productManufacturer={data.manufacturer}
                    quantityAvailable={data.quantity_available}
                    quantityOrdered={data.quantity_ordered}
                    quantityUnits={data.quantity_units}
                />
            </td>
            <td className="text-right">
                <SpecialPrice
                    isRefPrice={false}
                    data={data}
                    reload={reload}
                    numberOfItems={amount}
                />
                {data.prices != null ? 
                    <div className="p-1 table-subdata">
                        <PriceList prices={data.prices}/>
                    </div> : 
                    null
                }
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <PurchasePricesModal
                        size="small"
                        className="full-size-width"
                        productId={data.id}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                    />
                </RightsWrapper>
            </td>
            <td>
                <TradeActions
                    numberOfItems={amountChecked != null && amountChecked > 0 ? amountChecked : quantityMin}
                    onChangeNumberOfItems={this.changeAmount}
                    product={data}
                />
            </td>
        </tr>;
    }
}
