import React from 'react';
import {Carousel} from 'antd';
import NewsImage from './news/NewsImage';
import withDataHOC from '../dataProvider/withDataHOC';
import {ROUTES} from '../../constants/navigation';
import {GLOBAL_DATA} from '../../constants/globalData';
import {News as NewsDef} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
/**
 * @fero
 */

class CurrentNews extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.CATEGORY_NEWS]: NewsDef.isRequired,
    };

    render() {
        const news = this.props[GLOBAL_DATA.CATEGORY_NEWS];
        return <Carousel
            autoplay={true}
        >
            {
                news.map((newsArticle) => {
                    return <NewsImage
                        key={newsArticle.id}
                        newsArticle={newsArticle}
                    />
                })
            }
        </Carousel>
    }

}

export default withDataHOC([GLOBAL_DATA.CATEGORY_NEWS])(CurrentNews);