import React from 'react';
import locationHOC from '../locationProvider/locationHOC';
import CategoryPreview from './category/CategoryPreview';
import {Trans, t} from '@lingui/macro';
import {Products} from '../../constants/propTypesDefinitions';

/**
 * @fero
 */

class ProductCategories extends React.PureComponent {
    static propTypes = {
        products: Products.isRequired,
    };

    render() {
        const {products} = this.props;
        const categories = products != null && products.categories != null ? products.categories : [];
        return categories.length > 0 ? 
            <div className="d-flex flex-wrap m-3">
                {categories.map((category, index) => {
                    return <CategoryPreview
                        key={index}
                        category={category}
                    />
                })}
            </div> : null;
    }

}

export default locationHOC(ProductCategories);