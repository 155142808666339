// @flow
import React from 'react';
import PropTypes from 'prop-types'
import withSessionHOC from '../sessionProvider/withSessionHOC'
import SearchByLocation from '../general/SearchByLocation';
import {RIGHTS} from '../../constants/Rights';
import {QUERY_PARAMS} from '../../constants/navigation';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import locationHOC from '../locationProvider/locationHOC';

/**
 * @fero
 */

class ProductSearchBar extends React.PureComponent {
    static propTypes = {
        [SESSION_ATTRIBUTES.RIGHTS]: PropTypes.number.isRequired,
        location: PropTypes.object.isRequired,
    };

    render() {
        const {[SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        let paramsOnSearch = {
            [QUERY_PARAMS.PRODUCTS_ORDER_BY]: undefined,
            [QUERY_PARAMS.PRODUCTS_LIMIT]: 20,
            [QUERY_PARAMS.PRODUCTS_OFFSET]: undefined,
            [QUERY_PARAMS.PRODUCTS_LARGE_CATEGORY]: undefined,
            [QUERY_PARAMS.PRODUCTS_SHOW_FILTERS]: undefined,
            [QUERY_PARAMS.PRODUCTS_SCROLL]: undefined,
            [QUERY_PARAMS.SEARCH_CROSS_REFS]: undefined,
            [QUERY_PARAMS.ID_PRODUCT_GROUP]: undefined,
        };

        if(rights < RIGHTS.MARKETING)
        {
            // for customers always reset these filters
            paramsOnSearch[QUERY_PARAMS.ID_MANUFACTURER] = undefined;
            paramsOnSearch[QUERY_PARAMS.PRODUCTS_AVAILABILITY] = undefined;
        }

        let paramsOnErase = {
            // keep selected product group
            [QUERY_PARAMS.PRODUCTS_ORDER_BY]: undefined,
            [QUERY_PARAMS.ID_MANUFACTURER]: undefined,
            [QUERY_PARAMS.PRODUCTS_AVAILABILITY]: undefined,
            [QUERY_PARAMS.PRODUCTS_LARGE_CATEGORY]: undefined,
            [QUERY_PARAMS.PRODUCTS_SHOW_FILTERS]: undefined,
            [QUERY_PARAMS.PRODUCTS_IS_VISIBLE]: undefined,
            [QUERY_PARAMS.SEARCH_CROSS_REFS]: undefined,
            [QUERY_PARAMS.PRODUCTS_LIMIT]: 20,
            [QUERY_PARAMS.PRODUCTS_OFFSET]: undefined,
        };

        return <SearchByLocation
            textQueryParamKey={QUERY_PARAMS.DESIGNATION}
            typeQueryParamKey={QUERY_PARAMS.SEARCH_TYPE}
            paginationPageKey={QUERY_PARAMS.PRODUCTS_LIMIT}
            altSearchPageKey={QUERY_PARAMS.SEARCH_CROSS_REFS}
            additionalParams={paramsOnSearch}
            eraseParams={paramsOnErase}
        />;
    }

}

export default locationHOC(withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(ProductSearchBar));