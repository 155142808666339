import React from 'react';
import {Icon} from 'antd';
import {ROUTES} from '../../../constants/navigation';
import NavigateButton from '../../general/NavigateButton';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class AddCategory extends React.PureComponent {
    render() {
        return <NavigateButton to={ROUTES.CATEGORY_ADD} className="full-size-width" type="primary">
            <Icon type="plus" className="ml-1"/>
            <Trans>Pridanie produktovej kategórie</Trans>
        </NavigateButton>;
    }
}

export default AddCategory;