// @flow
import React from 'react';
import DeleteCategory from './categoryTree/DeleteCategory';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import withDataHOC from '../dataProvider/withDataHOC';
import MakeRequestWithItem from '../request/requests/MakeRequestWithItem';
import {RIGHTS} from '../../constants/Rights';
import {GLOBAL_DATA} from '../../constants/globalData';
import {navigateToParametrized} from '../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {Button, Icon, Tree } from 'antd';
import {locationHOC} from '../locationProvider/locationHOC';
import {getQueryParam} from '../../lib/url';
import {concatUnique} from '../../lib/array';
import {getParentsIds} from '../../lib/project';
import {Trans, t} from '@lingui/macro';
import Tooltip from '../general/Tooltip';
import { stopPropagation } from '../../lib/interaction';
import VisibilityIcon from '../general/VisibilityIcon';

const TreeNode = Tree.TreeNode;

/**
 * @fero
 */

class ProductsCategoriesTree extends React.PureComponent {
    constructor(props) {
        super(props);
        const categoryId = getQueryParam(props.location, QUERY_PARAMS.ID_PRODUCT_GROUP);
        this.state = {
            expandedCategories: categoryId != null ? [categoryId] : [],
        };
    }

    onSelect = (selectedKey) => {
        const {location} = this.props;
        const {expandedCategories} =this.state;
        this.setState({
            expandedCategories: concatUnique(expandedCategories, [selectedKey]),
        });
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ID_PRODUCT_GROUP]: selectedKey,
            [QUERY_PARAMS.PRODUCTS_SEARCH_OPTIONS]: undefined,
            [QUERY_PARAMS.PRODUCTS_ORDER_BY]: undefined,
            [QUERY_PARAMS.PRODUCTS_LIMIT]: undefined,
            [QUERY_PARAMS.PRODUCTS_LARGE_CATEGORY]: undefined,
            [QUERY_PARAMS.PRODUCTS_SCROLL]: undefined,
            [QUERY_PARAMS.SEARCH_CROSS_REFS]: undefined,
        });
    };

    onExpand = (expandedKeys) => {
        this.setState({
            expandedCategories: expandedKeys,
        });
    };

    treeNodeContent(node, selectedNode, showCounters, location) {
        return <div
            onClick={() => this.onSelect(node.id)}
            className="d-flex flex-wrap full-size-width overflow-hidden text-dark no-decoration-link"
        >
            <div className="white-space-normal overflow-hidden">
                <VisibilityIcon isVisible={node.is_visible}/>
                {node.name}
                {(showCounters && node.cnt != null) ? <span className="text-secondary">{"(" + node.cnt + ")"}</span> : null}
            </div>
            {selectedNode == node.id ? <RightsWrapper from={RIGHTS.MARKETING}>
                <Tooltip title={<Trans>Úprava produktovej kategórie</Trans>}>
                    <Button
                        size="small"
                        className="mx-1"
                        onClick={(ev) => {
                            stopPropagation(ev);
                            navigateToParametrized(location, ROUTES.CATEGORY_EDIT, {
                                [QUERY_PARAMS.ID_PRODUCT_GROUP]: node.id
                            });
                        }}
                    >
                        <Icon type="edit" />
                    </Button>
                </Tooltip>
                <DeleteCategory categoryId={node.id}/>
            </RightsWrapper> : null}
        </div>
    }

    tree(treeData, selectedNode, showCounters, location) {
        if (Array.isArray(treeData.children) && treeData.children.length >= 1) {
            return treeData.children.map((node) => {
                return <TreeNode
                    className="ant-tree-node-kbm"
                    title={this.treeNodeContent(node, selectedNode, showCounters, location)}
                    key={node.id}
                >
                    {node.children != null ? this.tree(node, selectedNode, showCounters, location) : null}
                </TreeNode>
            });
        } else {
            return null;
        }


    }

    render() {
        const {location} = this.props;
        const phrase = getQueryParam(location, QUERY_PARAMS.DESIGNATION);
        const categoryId = getQueryParam(location, QUERY_PARAMS.ID_PRODUCT_GROUP);
        const phraseNotEmpty = (phrase != null && phrase.trim() != '');
        const products = this.props[GLOBAL_DATA.PRODUCTS];
        const categoryTree = (phraseNotEmpty && products.tree != null) ?
            products.tree : this.props[GLOBAL_DATA.CATEGORY_TREE];

        let expandedKeys = concatUnique(this.state.expandedCategories, getParentsIds(categoryId));
        return <div>
            {phraseNotEmpty ?
                (categoryTree.length < 1 ? <NotFoundText location={location} phrase={phrase}/>
                        : <Trans>Nájdené v kategóriach:</Trans>
                ) : null }
            <Tree
                selectedKeys={[categoryId]}
                expandedKeys={expandedKeys}
                onExpand={this.onExpand}
            >
                {this.tree(categoryTree, categoryId, phraseNotEmpty, location)}
            </Tree>
        </div>
    }

}

export default withDataHOC([GLOBAL_DATA.PRODUCTS, GLOBAL_DATA.CATEGORY_TREE])(locationHOC(ProductsCategoriesTree));

function NotFoundText(props) {
    const {location, phrase} = props;
    const type = getQueryParam(location, QUERY_PARAMS.SEARCH_TYPE);

    let message = null;

    switch (type) {
        case 'prefix':
            message = <span>
                    <Trans>Nenašli sme žiaden produkt začínajúci na</Trans>
                {' '}
                <span className="font-weight-bold">{phrase}</span>
                    <Trans>. Skúste použiť voľbu "obsahuje".</Trans>
                </span>;
            break;
        case 'exact':
            message = <span>
                    <Trans>Nenašli sme žiaden s názvom</Trans>
                {' '}
                <span className="font-weight-bold">{phrase}</span>
                    <Trans>. Skúste použiť voľbu "začína na" alebo "obsahuje".</Trans>
                </span>;
            break;
        default:
            message = <span>
                    <Trans>Nenašli sme žiaden produkt obsahujúci</Trans>
                {' '}
                <span className="font-weight-bold">{phrase}</span>
                    <Trans>. Skúste zmeniť zadanie.</Trans>
                </span>;
            break;
    }
    return <div className="d-flex flex-column align-items-center">
        {message}
        <span className="mb-2 align-self-start"><Trans>Alebo nám môžete poslať dopyt.</Trans></span>
        <MakeRequestWithItem/>
    </div>
}