import React from 'react';
import PropTypes from 'prop-types';
import deepEqual  from 'deep-equal';
import withDataHOC from '../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../constants/globalData';
import {createFetchProductCrossReferences} from '../../backend/apiCalls';
import Spinner from '../graphics/Spinner';
import ProductCrossRefTable from './ProductCrossReferenceTable';
import locationHOC from '../locationProvider/locationHOC';
import { getQueryParam, getQueryParamNumber } from '../../lib/url';
import { QUERY_PARAMS } from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import InputText from '../general/InputText';
import InfoAttribute from '../general/InfoAttribute';

/**
 * @dusan
 */

class ProductCrossReferences extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            replacements: [],
            orderBy: undefined,
            loading: false,
            manufacturer: null,
        }
    }

    getFetchAttributes = (props, state) => {
        const {location} = props;
        const {orderBy, manufacturer} = state;

        const designation = getQueryParam(location, QUERY_PARAMS.DESIGNATION);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);

        return {
            designation: designation, 
            id_customer: customerId, 
            order: orderBy,
            manufacturer: manufacturer,
        };
    }

    fetchProductCrossRefs = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        const attributes = this.getFetchAttributes(this.props, this.state);

        if(attributes.designation == null || attributes.designation.trim() == '' || 
           attributes.manufacturer == null || attributes.manufacturer.trim() == '')
        {
            this.setState({
                replacements: [], 
                loading: false
            });
            return;
        }

        this.setState({loading: true});
        fetchHandler(
            createFetchProductCrossReferences(),
            attributes,
            (result) => {
                this.setState({
                    replacements: result,
                    loading: false,
                });
            }
        )
    };

    componentDidMount() {
        this.fetchProductCrossRefs();
    }

    componentDidUpdate(prevProps, prevState) {
        const attributes = this.getFetchAttributes(this.props, this.state);
        const prevAttributes = this.getFetchAttributes(prevProps, prevState);
        if (!deepEqual(attributes, prevAttributes)) {
            this.fetchProductCrossRefs();
        }
    }

    changeOrder = (newOrder) => {
        this.setState({orderBy: newOrder});
    }

    changeManufacturer = (newManuf) => {
        this.setState({manufacturer: newManuf});
    }


    render() {
        const { location } = this.props;
        const {replacements, orderBy, manufacturer} = this.state;

        const designation = getQueryParam(location, QUERY_PARAMS.DESIGNATION);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);

        return <div className="full-size-width full-size-height ">
            <div className="d-flex align-items-center">
                <h3 className="p-3">
                    <Trans>Alternatívy ku</Trans>
                    {' '}
                    {'"' + designation + '"'}
                </h3>
                <div style={{maxWidth: "20rem"}}>
                    <InfoAttribute
                        attributeTitle={<Trans>Výrobca:</Trans>}
                        attributeValue={
                            <InputText
                                value={manufacturer}
                                onChange={this.changeManufacturer}
                            />
                        }
                        isRequired={true}
                    />
                </div>
            </div>
            { replacements != null ? <ProductCrossRefTable
                    customerId={customerId}
                    crossReferences={replacements}
                    reload={this.fetchProductCrossRefs}
                    changeOrder={this.changeOrder}
                    orderBy={orderBy}
                /> 
                : <h4><Trans>Zadajte presné označenie</Trans></h4>
            }
        </div>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(ProductCrossReferences));