import React from 'react';
import PropTypes from 'prop-types';
import FilterWithImage from './filter/FilterWithImage';
import {Button, Collapse} from 'antd';
import {Trans, t} from '@lingui/macro'
import locationHOC from '../locationProvider/locationHOC';
import {createSetQueryParamValue, getQueryParam, navigateToParametrized, getQueryParamNumber} from '../../lib/url';
import {merge, removeNullAttributes} from '../../lib/object';
import {parseObjectFromQueryString} from '../../lib/project';
import {QUERY_PARAMS} from '../../constants/navigation';
import {ProductFilter, ProductManufacturer} from '../../constants/propTypesDefinitions';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import ManufacturersFilter from '../project/productTable/ManufacturersFilter';
import AvailabilitySelect from '../project/AvailabilitySelect';

const Panel = Collapse.Panel;

/**
 * @fero
 */

class ProductFilters extends React.PureComponent {
    static propTypes = {
        filters: PropTypes.arrayOf(ProductFilter.isRequired),
        manufacturers: PropTypes.arrayOf(ProductManufacturer.isRequired),
        [SESSION_ATTRIBUTES.IS_MOBILE]: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
        };
    }

    increaseLimit = () => {
        const {limit} = this.state;
        this.setState({limit: (limit + 10)});
    };

    onChange = (values) => {
        const {location} = this.props;
        const currentFiltersString = getQueryParam(location, QUERY_PARAMS.PRODUCTS_SEARCH_OPTIONS);
        const currentFilters = parseObjectFromQueryString(currentFiltersString);
        const newFilters = merge(currentFilters, values);
        removeNullAttributes(newFilters);
        const queryParam = Object.keys(newFilters).length > 0 ? JSON.stringify(newFilters) : null;
        createSetQueryParamValue(location, QUERY_PARAMS.PRODUCTS_SEARCH_OPTIONS)(queryParam);
    };

    onManufacturerChange = (manufId) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {[QUERY_PARAMS.ID_MANUFACTURER]: manufId});
    };

    onAvailabilityChange = (avail) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {[QUERY_PARAMS.PRODUCTS_AVAILABILITY]: avail});
    };

    onCollapseChange = (tab) => {
        const {location} = this.props;
        const showFilters = (tab == "filters") ? 1 : 0;
        navigateToParametrized(location, null, {[QUERY_PARAMS.PRODUCTS_SHOW_FILTERS]: showFilters});
    };

    clearAllParams = () => {
        const {location, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        let params = {
            [QUERY_PARAMS.PRODUCTS_SEARCH_OPTIONS]: undefined,
        };

        if(isMobile)
        {
            params[QUERY_PARAMS.ID_MANUFACTURER] = undefined;
            params[QUERY_PARAMS.PRODUCTS_AVAILABILITY] = undefined;
        }

        navigateToParametrized(location, null, params);
    };

    render() {
        const {filters, manufacturers, location, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        const {limit} = this.state;
        const currentFiltersString = getQueryParam(location, QUERY_PARAMS.PRODUCTS_SEARCH_OPTIONS);
        const currentFilters = parseObjectFromQueryString(currentFiltersString);
        const haveFilters = filters != null && filters.length > 0;
        const haveManufacturers = manufacturers != null && manufacturers.length > 0;
        const manufacturerId = getQueryParamNumber(location, QUERY_PARAMS.ID_MANUFACTURER);
        const productGroupId = getQueryParam(location, QUERY_PARAMS.ID_PRODUCT_GROUP);
        const availability = getQueryParam(location, QUERY_PARAMS.PRODUCTS_AVAILABILITY);
        const isFiltering = currentFiltersString != null || (isMobile && manufacturerId != null);
        //add class filter-panel-products to panel
        return productGroupId != null && productGroupId != 'C' ? 
            <div className="py-3">
                <Collapse onChange={this.onCollapseChange}>
                    <Panel className="" header={<Trans>Výber na základe parametrov</Trans>} key="filters">
                        <div key="filters" className="d-flex flex-wrap">
                            { isMobile && haveManufacturers ? 
                                <div className="p-2 product-filters">
                                    <div className="pb-2"><Trans>Výrobca</Trans></div>
                                    <ManufacturersFilter
                                        className="full-size-width"
                                        manufacturers={manufacturers}
                                        onChange={this.onManufacturerChange}
                                        value={manufacturerId}
                                    />
                                </div> : 
                                null                        
                            }
                            { isMobile ?
                                <div className="p-2 product-filters">
                                    <div className="pb-2"><Trans>Dostupnosť</Trans></div>
                                    <AvailabilitySelect
                                        className="full-size-width"
                                        value={availability}
                                        onChange={this.onAvailabilityChange}
                                    />
                                </div> : 
                                null 
                            }
                            {haveFilters ?
                                filters.map((filter, index) => {
                                    return index < limit ? 
                                    <FilterWithImage
                                        key={index}
                                        filter={filter}
                                        onChange={this.onChange}
                                        currentFilterValues={currentFilters != null ? currentFilters[filter.id] : {}}
                                    /> : null;
                                }) : 
                                null
                            }
                            { haveFilters && filters.length > limit ? 
                                <div className="p-2 product-filters d-flex align-items-end">
                                    <Button
                                        type="primary"
                                        icon="double-right"
                                        disabled={filters.length <= limit}
                                        onClick={this.increaseLimit}
                                    >
                                        <Trans>Ďalšie filtre</Trans>
                                    </Button>
                                </div> :
                                null
                            }
                        </div>
                        
                        <div key="navigation" className="pt-3">
                            <Button
                                type={isFiltering ? 'danger' : null}
                                onClick={this.clearAllParams}
                            >
                                <Trans>Vyčistiť všetky filtre</Trans>
                            </Button>
                        </div>
                    </Panel>
                </Collapse>
            </div> : null;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(locationHOC(ProductFilters));