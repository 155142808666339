import React from 'react';
import Link from '../../navigation/Link';
import Image from '../../general/Image';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {Category} from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */

class CategoryPreview extends React.PureComponent {
    static propTypes = {
        category: Category.isRequired,
    };

    render() {
        const {category} = this.props;
        const categoryChecked = category != null ? category : {};
        return <Link
            className="d-flex category-preview text-dark no-decoration-link"
            to={null}
            queryParams={{
                [QUERY_PARAMS.ID_PRODUCT_GROUP]: categoryChecked.id,
                [QUERY_PARAMS.PRODUCTS_LIMIT]: null,
                [QUERY_PARAMS.PRODUCTS_LARGE_CATEGORY]: undefined,
            }}
        >
            <Image
                src={categoryChecked.image}
                className="category-image"
                wrapperClassName="category-image-wrapper"
                alt="category"
            />
            <h4 className="p-3">{categoryChecked.name}</h4>
        </Link>;
    }

}

export default CategoryPreview;