import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../general/Image';
import FilterMultiselect from './FilterMultiselect';
import {Collapse, Tooltip} from 'antd';
import {Trans, t} from '@lingui/macro'
import {ProductFilter} from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */

class FilterWithImage extends React.PureComponent {
    static propTypes = {
        filter: ProductFilter.isRequired,
        currentFilterValues: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {filter, onChange, currentFilterValues} = this.props;
        const filterChecked = filter != null ? filter : {};
        return <div className="p-2 product-filters">
            <Tooltip placement="leftTop" title={
                filterChecked.image != null && filterChecked.image != "" ? 
                    <Image
                        alt="filterImage"
                        className="product-filter-image"
                        src={filterChecked.image}
                    /> : null
            }>
                <div className="pb-2">{filterChecked.name}</div>
            </Tooltip>
            <FilterMultiselect
                filter={filterChecked}
                onChange={onChange}
                currentFilterValues={currentFilterValues}
            />
        </div>;
    }

}

export default FilterWithImage;