import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'antd';
import {Trans, t} from '@lingui/macro';
import DisplayModal from '../general/DisplayModal';
import { Decimal } from '../../constants/propTypesDefinitions';

/**
 * @fero
 */

class ProductCrossDeviations extends React.PureComponent {
    static propTypes = {
        originalDesignation: PropTypes.string.isRequired,
        originalManufacturer: PropTypes.string,
        replacementDesignation: PropTypes.string.isRequired,
        replacementManufacturer: PropTypes.string.isRequired,
        deviations: PropTypes.arrayOf(PropTypes.shape({
            parameter: PropTypes.string.isRequired,
            original_val: Decimal,
            replacement_val: Decimal,
        })).isRequired,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "",
    };

    render() {
        const {className, originalDesignation, replacementDesignation, originalManufacturer, replacementManufacturer, deviations} = this.props;
        return <DisplayModal
            className={className}
            openNode={
                <div className="cursor-pointer">
                    <Icon type="warning" theme="twoTone" twoToneColor="#ffbf00"/>
                    <span className="pl-2 table-subdata"><Trans>zobraziť odchýlky</Trans></span>
                </div>
            }
            title={<span><Trans>Odchýlky</Trans></span>}
            modalClassName="middle-modal"
        >
            <table>
                <tr>
                    <th className="p-2 text-right"><Trans>Označenie:</Trans></th>
                    <th className="p-2 text-center">{originalDesignation}</th>
                    <th className="p-2 text-center"><Trans>vs.</Trans></th>
                    <th className="p-2 text-center">{replacementDesignation}</th>
                </tr>
                <tr>
                    <td className="p-2 text-right"><Trans>Výrobca:</Trans></td>
                    <td className="p-2 text-center">{originalManufacturer}</td>
                    <td className="p-2 text-center"></td>
                    <td className="p-2 text-center">{replacementManufacturer}</td>
                </tr>
                { deviations.map((dev, idx) => {
                    return <tr key={idx}>
                        <td className="p-2 text-right">{dev.parameter + ':'}</td>
                        <td className="p-2 text-center">{dev.original_val}</td>
                        <td className="p-2 text-center"></td>
                        <td className="p-2 text-center">{dev.replacement_val}</td>
                    </tr>;
                })}
            </table>
        </DisplayModal>;
    }

}

export default ProductCrossDeviations;