import React from 'react';
import PropTypes from 'prop-types';
import locationHOC from '../../locationProvider/locationHOC';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import InputText from '../../general/InputText';
import InputTextArea from '../../general/InputTextArea';
import InputNumber from '../../general/InputNumber';
import {Button, Form, Radio} from 'antd';
import {focus} from '../../../lib/project';
import {RIGHTS} from '../../../constants/Rights';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {createFetchRequestAdd} from '../../../backend/apiCalls';
import {navigateToParametrized} from '../../../lib/url';
import {Location, ButtonSize} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { TRADE } from '../../../constants/trade';
import CustomersSelect from '../../project/CustomersSelect';
const FormItem = Form.Item;

/**
 * @fero
 */


class MakeRequestWithItem extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        wrapperClassName: PropTypes.string,
        type: PropTypes.string,
        size: ButtonSize,
    };

    static defaultProps = {
        wrapperClassName: '',
        type: 'primary',
    };

    render() {
        const {location, wrapperClassName, type, size} = this.props;
        return <FormModal
            className={wrapperClassName}
            openNode={
                <Button type={type} size={size}>
                    <Trans>Vytvoriť dopyt</Trans>
                </Button>
            }
            values={{}}
            onFinishSuccessful={(requestId) => {
                navigateToParametrized(location, ROUTES.REQUEST_DETAILS, {[QUERY_PARAMS.ID_REQUEST]: requestId})
            }}
            title={<Trans>Vytvorenie dopytu</Trans>}
            Form={RequestAddItemFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vytvoriť dopyt.`)}
        />;
    }

}

export default locationHOC(MakeRequestWithItem);

class MakeRequestWithItemFrom extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({}).isRequired,
        onTradeChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        focus(this.first);
    }

    createHandleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const params = {
                    trade: formValues.trade,
                    id_customer: formValues.id_customer,
                    customer_notices: formValues.customer_notices,
                    dealer_notices: formValues.dealer_notices,
                    items: [
                        {
                            customer_code: formValues.customer_code,
                            quantity: formValues.quantity,
                            unit_price: formValues.unit_price,
                            customer_notice: formValues.customer_notice,
                            dealer_notice: formValues.dealer_notice
                        }
                    ]
                };
                createFetchRequestAdd()(params).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <FormItem
                        {...formItemLayout}
                        label={<Trans>Organizácia</Trans>}
                    >
                    {getFieldDecorator('id_customer')(
                        <CustomersSelect/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Typ</Trans>}
                >
                    {getFieldDecorator('trade', {initialValue: TRADE.SELL})(
                        <Radio.Group>
                            <Radio.Button value={TRADE.BUY}><Trans>Odoslaný dopyt</Trans></Radio.Button>
                            <Radio.Button value={TRADE.SELL}><Trans>Prijatý dopyt</Trans></Radio.Button>
                        </Radio.Group>
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka k dopytu</Trans>}>
                {getFieldDecorator('customer_notices', {})(
                    <InputTextArea/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Interná pozn. k dopytu</Trans>}>
                    {getFieldDecorator('dealer_notices', {})(
                        <InputTextArea/>
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov produktu</Trans>}>
                {getFieldDecorator('customer_code', {rules: [mandatoryRule]})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Počet</Trans>}>
                {getFieldDecorator('quantity', {rules: [mandatoryRule]})(
                    <InputNumber/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Žiadaná cena za jednotku</Trans>}>
                    {getFieldDecorator('unit_price', {})(
                        <InputNumber/>
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka k produktu</Trans>}>
                {getFieldDecorator('customer_notice', {})(
                    <InputText/>
                )}
            </FormItem>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Interná pozn. k produktu</Trans>}>
                    {getFieldDecorator('dealer_notice', {})(
                        <InputText/>
                    )}
                </FormItem>
            </RightsWrapper>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submitDraft" type="primary" loading={loading}
                        onClick={this.createHandleSubmit}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const RequestAddItemFormWrapped = Form.create()(MakeRequestWithItemFrom);