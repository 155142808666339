import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../general/Select';
import {ProductFilter} from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */

const UNIT_SEPARATOR = " ";

class FilterMultiselect extends React.PureComponent {
    static propTypes = {
        filter: ProductFilter.isRequired,
        currentFilterValues: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func.isRequired,
    };

    onChange = (option) => {
        const {onChange, filter} = this.props;
        const {unit = ""} = filter;
        const id = filter.id;
        const optionsChecked = option != null && option.length ? option : null;
        const optionWithoutUnit = optionsChecked != null ? optionsChecked.map(
            option => option != null ? option.replace((UNIT_SEPARATOR + unit), '') : null
        ) : optionsChecked;
        const parameterObject = {[id]: optionWithoutUnit};
        onChange(parameterObject);
    };

    render() {
        const {filter, currentFilterValues} = this.props;
        const unitChecked = filter != null && filter.unit != null ? filter.unit : "";
        const optionsChecked = filter != null && filter.options != null ? filter.options : [];
        const optionsWithUnits = optionsChecked.map(
            (option) => {
                return {
                    ...option,
                    val: (option.val + UNIT_SEPARATOR + unitChecked),
                }
            }
        );
        const filterValuesWithUnits = currentFilterValues != null ? currentFilterValues.map(
            value => value + UNIT_SEPARATOR + unitChecked)
            : [];
        return <Select
            value={filterValuesWithUnits}
            isMulti={true}
            onChange={this.onChange}
            className="full-size-width"
            options = {optionsWithUnits.map((option, index) => {
                return {
                    label: <div>
                        <span>{option.val}</span>
                        <span className="text-secondary">{"(" + option.cnt + ")"}</span>
                    </div>,
                    value: (option.val) ,
                };
            })}
        />;
    }

}

export default FilterMultiselect;